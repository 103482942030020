import React, { useState, useEffect } from "react";
import "./mbrdiExperience.scss";
import "intersection-observer";
import Banner from "../../common/Banner/banner";
import data from './mbrdi.json'
import { Loader } from "../../common/Loader/Loader";
import iconPathURL from "../../common/iconPath";

const iconPath = process.env.PUBLIC_URL + "/assets/images/mbrdi/";

export default function MbrdiExperience(props) {
  const [isVisible, setVisibility] = useState(false);
  const aboutData = data.data;
  const [loading, setLoading] = useState(true);
  useEffect(()=>{
  
    //  window.scrollTo(0, 0);
    setTimeout(()=>{
      setLoading(false)
    },100)
  },[])
  const hairLine = () => {
    return (
      <hr
        style={{
          backgroundColor:"#4A4A4A",
         // margin: 20,
          height: 0.7,
          width: "120%",
        }}
      />
    );
  };
    return (
        <div className="mbrdi-container">
        {loading &&<Loader></Loader>}
        <Banner imgSrc={iconPathURL('mbrdi/experiences.png')} title="The MBRDI Experience" />
          <section className="text-container-section col-xs-12 col-sm-12 col-md-12 col-lg-10">
          <div className="img-content">
          <div className="second-content">
            <p className="para-content">{aboutData.p1}</p>
            <p className="para-content">{aboutData.p2}</p>
            <p className="para-content">{aboutData.p3}</p>
          </div>
          </div>
         
          </section>
        
        </div>
    )
  }