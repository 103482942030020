import React, { useState, useEffect } from "react";
import { propTypes } from "react-bootstrap/esm/Image";
import "./footer.scss"

const ContactFooter =(props)=>{
    const hairLine =()=>{
      return(
          <hr
          style={{
            color: '#d3d3d3',
            margin:20,
            height: '0px',
          }}
        />
      )
    }
    return(
      <div>
         {hairLine()}
          {/* <p className="queries"> <a href="mailto:mbrdi_communications@Mercedes-Benz.com">mbrdi_communications@Mercedes-Benz.com</a> </p> */}
          <div className="email-text-content">
             {props.html()}
          </div>
     </div>
    )
  }

  export default ContactFooter;