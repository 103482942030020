import React, { useState, useEffect } from "react";
import "./pressRelease.scss";
import "intersection-observer";
import data from "./press.json";
import { Card } from "react-bootstrap";
import Button from "../../common/Buttons/button";
import ContactFooter from "../../common/ContactFooter/contactFooter";
import TwoColumnLayout from "../../common/TwoColumnLayout/TwoColumnLayout";
import Banner from "../../common/Banner/banner";
import iconPathURL from "../../common/iconPath";
import docPathURL from "../../common/docPath";

const iconPath = process.env.PUBLIC_URL + "/assets/images/";

const CardView = (props) => {

  const body = () => {
    return (
      <div>
        <h2 className="address">{props.item.p1}</h2>
        <div>
          <p>
            <strong>
              <p className="date">{props.item.date}</p>
            </strong>
          </p>
          <Button
            style="know-more-button-content"
            label="Read more"
            doc={props.item.doc}
            type={props.item.type}
          />
        </div>
      </div>
    );
  };
  return (
    <div className="text-container-section col-xs-12 col-sm-12 col-md-8 col-lg-8">
      <TwoColumnLayout
        imgStyle="col-xs-12 col-sm-4 col-md-4 col-lg-4 first-column"
        style="col-xs-12 col-sm-8 col-md-8 col-lg-8 second-column"
        imgSrc={`${iconPath}press/${props.item.image}`}
        body={body()}
      ></TwoColumnLayout>
    </div>
  );
};

export default function PressRelease(props) {
  const [isVisible, setVisibility] = useState(false);
  const [image, setImage] = useState("");
  const pressData = data.data;

  return (
    <div className="press-container">
      <Banner
        imgSrc={iconPathURL('press/press_header.png')}
        title="Press Release"
      />
      <div>
        {pressData.map((item, index) => {
          return (
            <CardView
              item={item}
              index={index}
              setVisibility={(state) => setVisibility(state)}
              isVisible={isVisible}
              setImage={(img) => setImage(img)}
            />
          );
        })}
      </div>
      <ContactFooter 
      screen="press"
      html={()=>{
        return(
        <span>For media queries, please write to MBRDI Communications<a className="email-link" href="mailto: mbrdi_communications@Mercedes-Benz.com">{" mbrdi_communications@Mercedes-Benz.com"} </a></span>
      )}}
      label="For media queries, please write to MBRDI Communications" />
    </div>
  );
}
