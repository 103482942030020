import React, { useRef } from "react";
import { Link } from "react-router-dom";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import "./MediaSliderSignal.scss";

const MediaSliderSingal = () => {
  const carouselRef = useRef(null);
  const iconPath = process.env.PUBLIC_URL + "/assets/images/";
  const Carousel_data = [
    {
      id: 1,
      SlideImg: "sustainability-garage/img5.png",
      DataText:
        "Mercedes-Benz Research and Development India launches Sustainability Garage",
      LabelLink: " ",
      LabelText: "",
      UploaddDate: "The Hindu, Feb 22 2024",
      GoToLink:
        "https://www.thehindu.com/news/cities/bangalore/mercedes-benz-research-and-development-india-launches-sustainability-garage/article67871036.ece",
    },

    {
      id: 2,
      SlideImg: "sustainability-garage/img6.png",
      DataText:
        "A CSR initiative of MBRDI, the Sustainability Garage will serve as a hub for multidisciplinary research",
      LabelLink: " ",
      LabelText: "",
      UploaddDate: "Business Standard, Feb 22 2024",
      GoToLink:
        "https://www.business-standard.com/companies/news/mercedes-benz-research-and-development-india-launches-sustainability-garage-124022200734_1.html",
    },
    {
      id: 3,
      SlideImg: "sustainability-garage/img7.png",
      DataText:
        "Mercedes-Benz India Conducts First Edition Of Sustainability Dialogue",
      LabelLink:
        "https://www.mobilityoutlook.com/authors/mobility-outlook-bureau/",
      LabelText: "Mobility Outlook Bureau,",
      UploaddDate: "Jan 20 2023",
      GoToLink:
        "https://www.mobilityoutlook.com/news/mercedesbenz-india-conducts-first-edition-of-sustainability-dialogue/",
    },
  ];

  const handlePrev = () => {
    if (carouselRef.current) {
      carouselRef.current.decrement();
    }
  };

  const handleNext = () => {
    if (carouselRef.current) {
      carouselRef.current.increment();
    }
  };

  return (
    <>
      <button onClick={handlePrev} className="prev-btn">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="#ffff"
          class="bi bi-caret-left-fill"
          viewBox="0 0 16 16"
        >
          <path d="m3.86 8.753 5.482 4.796c.646.566 1.658.106 1.658-.753V3.204a1 1 0 0 0-1.659-.753l-5.48 4.796a1 1 0 0 0 0 1.506z"></path>
        </svg>
      </button>
      <div></div>
      <Carousel
        infiniteLoop
        interval={3000}
        showThumbs
        showStatus={false}
        showArrows={false}
        swipeable={true}
        emulateTouch={true}
        stopOnHover={true}
        showIndicators
        ref={carouselRef}
      >
        {Carousel_data.map((data) => (
          <>
            <div className="media-slider-container">
              <div className="slider-wrapper">
                <div className="image-header-container">
                  <img
                    src={`${iconPath}${data.SlideImg}`}
                    className="slider-image"
                  />
                </div>
                <div className="header-label-wrapper">
                  <p className="title-text">{data.DataText}</p>
                  <p className="subtitle-text">
                    {!data.false && data.LabelLink && data.LabelText && (
                      <Link target="blank" to={data.LabelLink}>
                        {data.LabelText}
                      </Link>
                    )}
                    {data.LabelLink && data.LabelText ? (
                      <span>&nbsp;</span>
                    ) : (
                      ""
                    )}
                    {data.UploaddDate}
                  </p>
                  <div className="read-more-wrapper">
                    <Link target="blank" to={data.GoToLink} className="button">
                      Read More
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </>
        ))}
      </Carousel>
      <button onClick={handlePrev} className="next-btn">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="#fff"
          class="bi bi-caret-right-fill"
          viewBox="0 0 16 16"
        >
          <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z"></path>
        </svg>
      </button>
    </>
  );
};

export default MediaSliderSingal;
