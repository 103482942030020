
import './details.scss';
import 'react-inner-image-zoom/lib/InnerImageZoom/styles.css'
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import iconPathURL from '../../common/iconPath';
const iconPath = process.env.PUBLIC_URL + "/assets/images/";

const DetailedView = ({url, handleClose, show, children }) => {
  const showHideClassName = show ? "modal display-block" : "modal display-none";

  return (
    <div className="detail-container">
      
      <Zoom>
        <img src={url} alt="test" className="img-modal" />
        </Zoom>
      
   
    </div>
  );
};

export default DetailedView;
