import React, { useState, useEffect,useRef } from "react";
import "./joinStar.scss";

import 'bootstrap/dist/css/bootstrap.min.css';
import { Link, useHistory } from "react-router-dom";
import Button from "../../common/Buttons/button";
import Modal from "../../common/Modal/modal";
import ContactFooter from "../../common/ContactFooter/contactFooter";


import Banner from "../../common/Banner/banner";
import iconPathURL from "../../common/iconPath";
import questions from "./questions.json";
import $ from 'jquery';
import PopUp from "../../common/Popup/popUp"
import { logAnalyticsEvent } from "../../utils/firebaseConfig";

const iconPath = process.env.PUBLIC_URL + "/assets/images/";
const iconPathBaseUrl = process.env.PUBLIC_URL + "/assets/icons/";

export default function JoinStar(props) {
  const jobsRef = useRef(null)
  const navigate = useHistory();
  const [isVisible, setVisibility] = useState(false);
  const [image, setImage] = useState("");
  const [isActive,setIsActive] = useState(false);
  const [listOfQuestions,setListOfQuestions] = useState(questions)
  const [showModelPopUp,setShowModelPopUp] = useState(true)
  const toggleMenu = (index) =>{
    setIsActive(true)
    let menus = {...listOfQuestions};
    console.log(menus['menus'][index]);
    if(menus['menus'][index]['isActive']){
    
        $("#"+index+"-subLinks1").slideUp("slow");
    }else{
     
        $("#"+index+"-subLinks1").slideDown("slow");
    }
    menus['menus'][index]['isActive'] = !menus['menus'][index]['isActive'];
    setListOfQuestions(menus);
    
}
const toggleSubMenu = (index,subIndex) =>{
  let menus = {...listOfQuestions};
  console.log(menus['menus'][index]);
  if(menus['menus'][index]['subMenu'][subIndex]['isActive']){
      $("#"+subIndex+"subLinks1").slideUp("slow");
  }else{
      $("#"+subIndex+"subLinks1").slideDown("slow");
  }
  menus['menus'][index]['subMenu'][subIndex]['isActive'] = !menus['menus'][index]['subMenu'][subIndex]['isActive']
  setListOfQuestions(menus);
  
}

const navigateLink = (link) =>{
 const win = window.open("/some-link", "_blank");
  win.focus();
 
}

logAnalyticsEvent("MBRDI_Joinstar");

function togglePopup() {
 setShowModelPopUp(false);
}

function showJobs (){
    window.open("https://jobs.mercedes-benz.com/en?en=&ParentOrganization=[140]&PositionLocation.Country=[390]", '_blank');
  }
  return (
    <div className="join-star-container">  
     {showModelPopUp && 
      <PopUp toggleShowPopup= {() => setShowModelPopUp(false)} />
    }
      <div className="banner-parent-conetnt">
          <img src={iconPathURL('joinStar/joinus.png')} className="banner-image"/>
         
         <div className="banner-content">
             
              <h2 className="hiring-title">
             We’re HIRING!
              </h2>
               <p>
               <span style = {{cursor: "pointer"}} onClick={showJobs} className="click-here">Click here</span>
               </p>
         </div>
      </div>
     
      <div className="join-center-container">
          <section className="opp-section">
              <div className="opp-section-div col-xs-12 col-sm-12 col-md-8 col-lg-8">
                <h2>MBRDI, an Equal Opportunity Employer</h2>
                <p className="first-p">
                A diverse workforce that takes along every section of the society, is non-negotiable to MBRDI, given the definite richness of perspective such dynamism brings on-board. And for that reason, we go beyond accepting differences in individual characteristics by welcoming it, and doing all in our capacity to progressively build an environment where everyone feels supported to bring their best to work, each day. We are committed to enabling equal opportunities, and to fostering an inclusive work ecosystem.</p>
                <p className="second-p">
                MBRDI remains dedicated to enabling everyone on the team to achieve their full potential by staying focussed fully on one’s merit, qualifications and competence, while championing ample learning and development avenues that nurture regular upskilling, for all. Towards strengthening our diversity and inclusion goals actively, MBRDI forbids discrimination based on age, colour, gender, gender identity or expression, religion, race, caste, disability, marital status, pregnancy or any other personal characteristics.</p>
                <p className="third-p">
                You’re encouraged to go on and explore the page, to know more about the nature of opportunities we have on offer, the hiring process, and the ways to go about giving it your best shot. However, rest assured, through it all, that we’re unwavering in our commitment as an Equal Opportunity Employer, since it’s just the right thing to do!</p>
              </div>
          </section>
         
          <section className="job-application-section col-xs-12 col-sm-12 col-md-8 col-lg-8">
          <h2>Opportunities Galore: A chance to be part of the Star, Like Never Before!</h2>
            <div className="opp-link row" ref={jobsRef}>
                <div className="col-xs-12 col-md-4  col-sm-4  col-lg-4" >
                <a onClick={showJobs}><img src={iconPathURL('joinStar/mbrdi-job.png')}></img></a>
                </div>
              
                <div className="col-xs-12 col-md-4  col-sm-4  col-lg-4" >
                <a target="new" href="/#hiring-opportunities"><img src={iconPathURL('joinStar/hackathon.png')}></img></a>
                </div>
                <div className="col-xs-12 col-md-4  col-sm-4  col-lg-4" >
                <a target="new" href="/#pwds-mbrdi"><img src={iconPathURL('joinStar/pwdspng.png')}></img></a>
                </div>
             
            </div>
            <h2>The MBRDI Job Application Process</h2>
            <div className="job-steps box-shadow"> 
                <p>
                We appreciate applying for jobs can be rather anxiety inducing. But we do feel it doesn’t have to be so. Here’s a transparent lowdown* <i>of stages your application progresses through.</i>  
                </p>
                <div className="job-steps-image">
                    <img src={iconPathURL('joinStar/chain.png')}></img>
                </div>
                <p>
                  <i>This is for an indicative understanding of the overall application journey. Process may vary depending on department needs, role demands and skillset requirements.</i>
                  </p>
            </div>

          
            <section className="fitfh-section">
              <div className="">
                <h2>Tips – How to make that lasting impact?</h2>
                <p className="first-p">
             
                <ul>
                  <li>
                    <span>Punctuality</span> matters
                  </li>
                  <li>
                  A little research goes a long way – <span>do read all about MBRDI and the panel members</span>should you have been provided prior information by the recruiter
                  </li>
                  <li>
                    <span>Brush up</span>on the topics your relevant to your candidature, and if experienced, make notes of pertinent experience that would win you points! 
                  </li>
                  <li>
                  Stay on top of the Job Description whether applying or appearing for the interview
                  </li>
                  <li>
                  Make an impression – <span>do dress suitably</span> for the interview
                  </li>
                  <li>
                  Most important, wear that smile, and your confidence!
                  </li>
                </ul>
                  <h2>
                  Checklist of things to do upon landing an interview at MBRDI:
                  </h2>
                  <ul>
                  <li>
                    As an applicant,<span>your CV needs to be posted against a vacancy seen on the Applicant Tracking System</span>
                  </li>
                  <li>
                  <span>  Carry your most recent resume, and ensure you are carrying identity proofs, or other pre-requested documents, with your passport-sized photograph,</span> for the discussion
                  </li>
                  <li>
                 <span> Do bring along your interview call letter, either in print or e-format, </span>for the security personnel to grant you entry to the location
                  </li>
                  <li>
                  For security reasons, being an Research & Development centre, <span>smartphones and laptops are prohibited</span>
                  </li>
                  <li>
                  <span>Personal laptops/notepads and smartphones will need to be handed in at the reception></span> until you are at the MBRDI premises. These may be collected post the interview
                  </li>
                  <li>
                  We recommend you keep your family/friends and stakeholders apprised, before handing the above, in
                  </li>
                </ul>
              </p>
                <div className="questions">
                    <h2>Frequently Asked Questions: (FAQs)</h2>
                    <p className="accordian">
                    <ul className="links">
                    {listOfQuestions['menus'].map((links,index) => (
                        <li className="questions-li"> 
                        {links['hasSubmenu'] &&
                            <div className="row toggle-row"  onClick={()=>toggleMenu(index)}>
                                <div className="col-lg-11 col-xs-11">
                                  <p className="question-title">{links['title']}</p>     
                                </div>
                                <div className="col-lg-1">
                                <span className="toggle-questions">{!links['isActive']?<img src={`${iconPathBaseUrl}/expand.png`}></img>:<img src={`${iconPathBaseUrl}/collapse.png`}></img>} </span>
                                </div>

                            </div>
                               
                              }
                              {!links['hasSubmenu'] &&
                                  <a target="new" href={links.title=="Event Report"?`${iconPath}Mercedes-Benz_Sustainability_Dialogue_India_2023_Report.pdf`:links['title']=="MBRDI on LinkedIn"&&"https://www.linkedin.com/company/mercedes-benz-research-and-development-india/"}
                                  class="primary-link"  onClick={()=>links.title!="Event Report"&&navigateLink(links['link'])}>{links['title']}
                                
                                    
                                      </a>    
                                        
                              }
                          
                      
                                {( links['hasSubmenu'])  &&
                                    <ul id={index+"-subLinks1"} className="subLinks">
                                      <li>
                                    {links['subMenu'].map((subLinks,subIndex) => (
                                          
                                              <p className="sub-text">{subLinks['title']}</p>
                                          
                                    ))}
                                    </li>
                                    </ul>
                                }
                            </li>
                        ))} 
                        </ul>
       
                    </p>
                </div>
              </div>
           </section>
          

          </section>
         
       
      </div>
     
    </div>
  );
}
