import React from "react";
import { Link } from "react-router-dom";
import "./SustainabilityGarage.scss";
import CardSingal from "./CardSingal";
import MediaSliderSingal from "./MediaSliderSingal";

// import { Header } from "./Layout/Header";
// import { Banner } from "./Banner/Banner";
// import { ParaContent } from "./TopSection/ParaContent";
// import { CardList } from "./What_We_Do/CardList";
// import { CallingApp } from "./CallingApp/CallingApp";
// import { MediaSlider } from "./Media/MediaSlider";
// import { Footer } from "./Layout/Footer";
const iconPath = process.env.PUBLIC_URL + "/assets/images/";

export default function SustainabilityGarage() {
  return (
    <>
      <div className="banner">
        <img
          src={`${iconPath}sustainability-garage/banner_img.jpg`}
          className="img-fluid"
          alt="banner"
        />
        <div className="sustainability-garage-banner-title">
          <div className="sustainability-header-container">
            <h1>
              Sustainability at <br />
              MBRDI
            </h1>
          </div>
        </div>
      </div>
      <section className="sustainability-garage-section firstElement">
        <div className="section-container">
          <p>
            At Mercedes-Benz, sustainability means permanently creating value
            for all stakeholder groups: for customers, employees, and investors
            as well as for our business partners and society as a whole.
            Economic, environmental, and social responsibility go hand in hand —
            along the entire value chain. In order to achieve long-term success,
            we need to live up to this responsibility.
          </p>
          <p>
            We take a holistic approach to sustainability through our focus
            areas - climate protection & air quality, resource conservation,
            livable cities, data responsibility, human rights and traffic
            safety. These focus areas are enabled by integrity, people and
            partnerships and are observed at all stages of the automotive value
            chain – from technical development to the extraction of raw
            materials and production, through service life and recycling, to the
            well-being of the workforce behind it.
          </p>
          <p>
            Our vision is a fully electric future of mobility. A core element of
            our sustainable business strategy is Ambition 2039, with which we
            already set the course in 2019 for a holistic approach to climate
            protection and air pollution control. The ambition is to make our
            entire fleet of new vehicles net carbon-neutral along the entire
            value chain and over the vehicles' entire life cycle by 2039.
          </p>
          <p>
            At Mercedes-Benz Research and Development India (MBRDI), we are
            committed to enabling the vision of the Mercedes-Benz Group AG
            towards an EV-only future. MBRDI’s team of digital, software, and
            mechanical engineers are deeply engaged in ensuring sustainability
            as a core priority – from analysing materials to design greener
            products or better battery chemistry to pivoting increasingly
            towards digital engineering processes that reduce emissions or
            waste.{" "}
          </p>
        </div>
      </section>

      <section
        className="sustainability-garage-section mercedes-garage"
        style={{
          backgroundImage: `url(${iconPath}sustainability-garage/img8.jpg)`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "top  center",
          backgroundSize: "cover",
        }}
      >
        <div className="section-container">
        
            <div className="sustainability-garage-icon-container">
            <img
              src={`${iconPath}sustainability-garage/sustainability-garage-icon.png`}
              // className="img-fluid"
              alt="icon"
            />
          </div>
          <p>
            Launched by MBRDI, Sustainability Garage – a Mercedes-Benz
            Initiative is a unique platform for diverse stakeholder groups to
            collaborate and champion sustainability goals.{" "}
          </p>
          <p>
            The first Sustainability Garage was set up at Prayoga Institute of
            Education Research as part of MBRDI’s ongoing Corporate Social
            Responsibility efforts. The Garage is equipped with state-of-the-art
            facilities, and serves as a hub for multidisciplinary research,
            focusing on developing eco-friendly materials with diverse
            applications to benefit society.
          </p>
        </div>
      </section>

      <section className="sustainability-garage-section whatwedo">
        <div className="section-container">
          <h2 className="sustainability-section-title">What we do</h2>
          <div className="row g-4">
            <CardSingal
              BGImg={`${iconPath}sustainability-garage/cardimg1.jpg`}
              TitleText="Centres of Excellence"
              ParaText="Enabling research on future forward and trending sustainability topics, accelerating innovation, enhancing productivity, and driving impactful discoveries. Empowering social enterprises and students working towards climate tech solutions through customised early-stage, incubation, and acceleration programs coupled with deep tech mentorships."
            />
            <CardSingal
              BGImg={`${iconPath}sustainability-garage/cardimg2.jpg`}
              TitleText="Academia"
              ParaText="Integrating with leading Indian and international educational institutions on curation of Sustainability curriculum. We aim to bring together diverse learning disciplines and foster collaboration and interdisciplinary learning."
            />
            <CardSingal
              BGImg={`${iconPath}sustainability-garage/cardimg3.jpg`}
              TitleText="Partnerships"
              ParaText="Creating an ecosystem of partnerships with leading industries, think tanks, public policy, and non-profit organisations to collectively work towards Sustainable Development Goals."
            />
          </div>
        </div>
      </section>

      <section
        className="sustainability-garage-section calling-app"
        style={{
          backgroundImage: `url(${iconPath}sustainability-garage/img3.jpg)`,
          backgroundRepeat: `no-repeat`,
          backgroundPosition: `center`,
          backgroundSize: `cover`,
          backgroundAttachment: "fixed",
        }}
      >
        <div className="section-container">
          <div className="row">
            <div className="col-md-7 col-lg-6">
              <div className="appcontent">
                <h3 className="sustainability-section-title">
                  Path to a Greener Future{" "}
                </h3>
                <p>
                  Introducing the Climate Tech Incubator Program- a nationwide
                  search for the most innovative social enterprises and students
                  working towards decarbonisation. This program focuses on
                  students and social enterprises with an entrepreneurial idea
                  in the themes of Sustainable Mobility and Environmental
                  Sustainability.
                </p>
                <div className="btnGroup">
                  <p>
                    <span>Social enterprises</span>
                  </p>
                  <a
                    target="new"
                    href="https://t-hub.co/programs/mbrdi-startup-accelerator/"
                    className="primaryBtn"
                  >
                    Apply Now
                  </a>
                </div>
                <div className="btnGroup">
                  <p>
                    <span>Students</span>
                  </p>
                  <a
                    // target="blank"
                    // to="https://zfrmz.com/X5meweCEWQahKtbr7WB4"
                    // className="primaryBtn"
                    target="new"
                    href="https://programs.t-hub.co/mbrdi-launchpad/"
                    className="primaryBtn"
                  >
                    Apply Now
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        className="sustainability-garage-section mediaslider"
        style={{
          backgroundImage: `url(${iconPath}sustainability-garage/slider_bg.jpg)`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundSize: "cover",
        }}
      >
        <div className="section-container">
          <h2 className="sustainability-section-title">Newsroom</h2>
          <div className="media-slider">
            <MediaSliderSingal />
          </div>
        </div>
      </section>
    </>
  );
}
