import React, { useState, useEffect } from "react";
import "./innovations.scss";
import "intersection-observer";
import data from "./innovations.json";
import { Link, useHistory } from "react-router-dom";
import Banner from "../../common/Banner/banner"
import iconPathURL from "../../common/iconPath";

const iconPath = process.env.PUBLIC_URL + "/assets/images/";

export default function Innovations(props) {
  const innovationData = data.data;
  const navigate = useHistory();

  const setNavigation = () => {
    navigate.push("/electric-routes");
  };
  const innovationTile = () => {
    return (
      <div className="tile-contents">
        <button className="tile-button" onClick={setNavigation}>
          <img
            className="innovation-tile"
            src={iconPathURL('innovations/innovation_4.png')}
          ></img>
          <div
            className="innovation-container"
            style={{ backgroundColor: "white" }}
          >
            <h3 className="innov-text">{"Taking the Electric Route"}</h3>
            <p className="para-innov">
              {
                "Mercedes-Benz Research and Development India (MBRDI) focuses on innovating and..."
              }
            </p>
            <p className="para-innov">
              <a>Read more</a>
            </p>
            <div className="date-content">
              <img className="icons" src={iconPathURL('common/schedule.png')} />
              <p className="date-innov">September 22, 2022</p>
            </div>
          </div>
        </button>
      </div>
    );
  };

  return (
    <div className="innovation-container">
      <Banner imgSrc={iconPathURL('innovations/innovation_1.png')} title="" />
      <div className="img-content">
        <section className="text-container-section col-xs-12 col-sm-12 col-md-9 col-lg-9">
          <div className="second-content">
            <h2 className="innovation-title">{innovationData.title}</h2>
            <p className="para-content">{innovationData.p1}</p>
            <p className="para-content">{innovationData.p2}</p>
            <p className="para-content">{innovationData.p3}</p>
          </div>
        </section>
      </div>
      <section
       
        style={{
          backgroundImage: `url(${iconPathURL(`innovations/innovation_2.png`)})`,
          backgroundRepeat: "round",
          flexDirection: "column",
        
        }}
      >
        <div  className="innovation-content" style={{backgroundColor:`rgba(0, 0, 0, 0.4)`}}>
        <p className="content-title">MBRDI Stories</p>
        {innovationTile()}
        </div>
      </section>
      <section
        className="img-content footer-content innovation-content"
        style={{
          backgroundImage: `url(${iconPathURL(`innovations/innovation_3.png`)}`,
          backgroundRepeat: "round",
        }}
      >
        <h2 className="heading-title footer-innovation">
          {"Stay tuned for more Star Tech stories \n to follow!"}
        </h2>
      </section>
    </div>
  );
}
