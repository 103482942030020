import React, { useState, useEffect } from "react";
import "./TwoColumnLayout.scss";

const iconPath = process.env.PUBLIC_URL + "/assets/images/";

export default function TwoColumnLayout(props) {
  console.log(props)

    return (
      <div className="two-column-layout">
  
       
                <div className="row box-shadow">
                    <div className={props.imgStyle}>
                    <img className="contacts-image" src={props.imgSrc}></img>
                    </div>
                    <div className={props.style}>
                    {props.body}
                    </div>
                </div>
               
     </div>
    )
  }