import React, { useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { clarity } from "react-microsoft-clarity";

import "./App.css";

import Home from "./containers/Home/home";
import AboutUs from "./containers/AboutUs/aboutUs";
import Header from "./components/Header/Header";
import Layout from "./components/Layout/Layout";
import { HashRouter as Router, Route, Switch } from "react-router-dom";

function App() {
  useEffect(() => {
    if (process.env.NODE_ENV === "production") {
      clarity.init("mj0hckmas7");
    }
  }, []);

  return (
    <div className="App">
      <Layout></Layout>
    </div>
  );
}

export default App;