import React, { useState, useEffect } from "react";


    const HairLine =()=>{
      
    
    return(
     
        
          <hr
          style={{
            color: '#d3d3d3',
            margin:20,
            //height: 'px',
          }}
        />
      
         
     
    )
        }
  

  export default HairLine;