import React, { useState, useEffect } from "react";
import Banner from "../../common/Banner/banner"
import "./WhatItMeansToBeMbrdi.scss";
import iconPathURL from "../../common/iconPath";

const iconPath = process.env.PUBLIC_URL + "/assets/images/";


export default function WhatItMeansToBeMbrdi() {

  
    return (
        <div className="what-mbrdi-container">

            <img className="what-mbrdi-image" src={iconPathURL('whatItMeans/its-means-tobe.png')}></img>
            <h1 className="press-text">What it means to be ‘MBRDI’</h1>
            
            <section className="text-container-section col-xs-12 col-sm-12 col-md-8 col-lg-8">
                <div className="first-box">
                    <h2 className="title-content">The MBRDI culture and spirit</h2>
                    <p className="first-text">Being the largest development centre <i>outside of Germany,</i> we at MBRDI, are appreciative of our role in taking the larger group closer towards the objective of defining both the next level of quality, safety and innovation, and the future of mobility.</p>
                    <p className="second-text">To this end, our people – at the core of everything we achieve – are our most prized assets. We are convinced that people are more motivated, more powerful and more satisfied if they can bring to the workplace their unique personalities and identities, i.e., adding value as they are. That is why we demand and promote equal opportunity and a culture of appreciation and respect. And to this end, befitting the changing times, we are all for embracing a start-up mindset and to contribute to the future of mobility in a safe and sustainable manner. MBRDI is ‘in for change’!</p>
                </div>

                <div className="second-box">
                  <h2 className="title-content">Leadership 20X – The culture of change</h2>
                    <img className="what-mbrdi-image1" src={iconPathURL('whatItMeans/leadership-cutout.jpg')}></img>
                    <p className="second-text">While you could learn about our central culture here, let’s just say in a nutshell that the MBRDI is aligned to the purpose of defining a dynamic new culture that will drive us to lead the future.  </p>
                    <p className="third-text">
                    In that, <i>Customer orientation</i> (the connected, digital and world-class propositions defining new-age mobility to delight customers), <i>innovation</i> (leveraging cross-departmental creativity and intelligence) <i>diversity</i> (in its myriad forms), <i>collaboration</i> (inter, intra-functional or international),<i>constant learning </i> ( includes the consciously-sought feedback, handling mistakes well as opportunities to improve, and willingness to develop each other),<i>agility</i>  (openness to change and adapt quickly, effectively and creatively),<i>empowerment</i>  (promotion of ownership, and nurturing of individual talents), and a <i>drive to win</i>  (the ubiquitous best-or-nothing principle that fuels our hunger to stay ahead of the curve) are far beyond words, and are a default reflection of a day in our employees everyday functioning. At MBRDI, we’re pleased with the strides we have taken towards how our people will lead, be led, and are working together.
                    </p>
                </div>

                <div className="third-box">
                 <h2 className="title-content">Diversity and Inclusion</h2>
                    <img className="what-mbrdi-image1" src={iconPathURL('whatItMeans/diversity.png')}></img>
                    <p className="second-text">Our people practices cut across gender, generational, cognitive, and cultural diversities, together with our being an employer conscious of creating an equitable environment for persons with disabilities.</p>
                    <p className="third-text">
                    Bertha Benz (wife of our founder Karl Benz), was the <i>first person ever to drive the first automobile</i>. The seeds of women leading the way therefore, were sown over a century back, at Mercedes-Benz. At MBRDI, we realise we have a tall and rich legacy to further. Our interventions aimed at attracting, developing, engaging and retaining women fulfil a critical function in having them contribute as active voices in our workforce.
                    </p>

                    <p className="third-text">
                    Besides, with a view to meeting the demands for a more flexible ecosystem, our steps towards enhancing inter-generational approachability and improving appreciation of inter-cultural nuances and sensitivities, right from an employee’s Day 1, makes inclusivity an integral aspect of our DNA.
                    </p>
                </div>
            </section>
        </div>
    )
  }