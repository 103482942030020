import React, { useState, useEffect,useRef } from "react";
import "./popup.scss"
const iconPath = process.env.PUBLIC_URL + "/assets/images/";
const iconPathBaseUrl = process.env.PUBLIC_URL + "/assets/icons/";

export default function PopUp(props) {
  function togglePopup() {
    props.toggleShowPopup(false);
   }
  return (
    <div className="popup">
    <div className="popup_inner">
    <p class="pop-title">
      Important Notice: Beware of Fake Job Offers!
    </p>
    <p className="pop-details">
    We would like to alert jobseekers regarding the possibility of fraudulent entities or people impersonating MBRDI or its employees and presenting fake job opportunities. Kindly ensure your safety by verifying information exclusively through our official communication channels, including our company website and LinkedIn pages.
    <br></br> <br></br>
    MBRDI never asks for any personal information or payment during the recruitment process.
    <br></br> <br></br>
    Your safety is our top priority.
    <br></br> <br></br>
    Thank you.
    <br></br>
    <button className= "button-ok" onClick={togglePopup}>Ok</button>
    </p>
    
  </div>
  </div>
  );
}
