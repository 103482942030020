import React, { useState, useEffect } from "react";
import "./home.scss";
import ReactCarousel from "../../common/carousel/carousel";
import Layout1 from "../../common/layout1/layout1";
import NewsRoom from "../../components/NewsRoom/newsRoom";
import "intersection-observer";
import { ScrollView } from "@cantonjs/react-scroll-view";
import data from "./home.json";
import Button from "../../common/Buttons/button";
import constants from "../../constants/constants";
import Modal from "../../common/Modal/modal";
import DetailedView from "../../components/DetailsCarousal/detailedView";
import {Link,useHistory} from "react-router-dom"
import axios from "axios";
import iconPathURL from "../../common/iconPath";
import { logAnalyticsEvent } from "../../utils/firebaseConfig";
const iconPath = process.env.PUBLIC_URL + "/assets/images/";


export default function Home(props) {
   const t = useHistory();
   const [source, setSource] = useState("");
   const [loading, setLoading] = useState(true);
  const [isVisible, setVisibility] = useState(false);
  const [image, setImage] = useState(false);


logAnalyticsEvent("MBRDI_Home");

  useEffect(()=>{
  
    //  window.scrollTo(0, 0);
    setTimeout(()=>{
      setLoading(false)
    },150)
  },[])
  const renderCarousalItem = (color) => {
    return (
      <div
        className="carousal-content"
        style={{ backgroundColor: color }}
      ></div>
    );
  };
 
  const navigateItem = (e) =>{
 
  if(e==3){
    t.push("/innovations")
  }
  console.log("----888")
    //
  }
  const homeData = data.data;  

  return (
    <div class="home-container">
       <section className="layout"   style={{ backgroundImage: `url(${iconPathURL('home/h4.png')})` , backgroundRepeat: "round"}}>
      <Modal show={isVisible} handleClose={() => setVisibility(false)}>
      <button className='button-container' onClick={() => setVisibility(false)}><img style={{ height: 20, width: 20 }} src={iconPathURL('common/close.png')}></img></button>
        <DetailedView
        url={image}
        />
          
      </Modal>
     {!loading?<ReactCarousel count={4}
       onClickItem={(e)=>navigateItem(e)}>
        <div className="img-content">
          <img className="img-content" src={iconPathURL('home/o1.png')}></img>
          <p className="legend">
            <img className="in-text" src={iconPathURL('home/o1text.png')}></img>
          </p>
        </div>
        <div className="img-content">
          <img className="img-content" src={iconPathURL('home/o2.png')}></img>
          <p className="legend">
            <img className="in-text" src={iconPathURL('home/in-mb.png')}></img>
          </p>
        </div>
        <div className="img-content">
          <img className="img-content" src={iconPathURL('home/o3.png')}></img>
          <p className="legend">
            <img src={iconPathURL('home/mbux-Breaking.png')}></img>
          </p>
        </div>
       
        <div className="img-content" >    
          <img className="img-content inoovation-item" src={iconPathURL('home/o4.png')}></img>
       
        </div>   
      </ReactCarousel>:null}
      <div style={{ backgroundColor: "white" }}>
      
        <section
          style={{
            backgroundImage: `url(${iconPathURL(`home/who.png`)})`,
            backgroundRepeat: "round",
          }}
        >
          <div className="who-we-are first-content">
            <div className="elementor-spacer-inner-hieght">&nbsp;</div>
            <div>
              <div className="margin-auto first-content-text">
                <h1 className="heading-title heading-title-white">{homeData[0].title}</h1>
                <p className="">{homeData[0].p11}<i>{homeData[0].i}</i>{homeData[0].p12}</p>
                <p>{homeData[0].p2}</p>
                <a href="#/about-us" >
                <Button
                //  url="/about-us"
                  label = "Know more"
                  
                ></Button>
                </a>
              </div>
            </div>
            <div className="elementor-spacer-inner"></div>
          </div>
        </section>

        <section>
          <div className="col-8  second-content">
            <h2 className="heading-title-black">{homeData[1].title}</h2>
            <p>{homeData[1].p1}</p>
          </div>
        </section>
        <section>
          <div className="image-layout third-content">
            <div className="row image">
              <div className="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                <section
                  className="feature-icon"
                  style={{
                    backgroundImage: `url(${iconPathURL(`home/i1.png`)})`,
                    backgroundRepeat: "round",
                  }}
                >
                  <h3 className="tile-content"><span>{constants.tile1}</span></h3>
                </section>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                <section
                  className="feature-icon"
                  style={{
                    backgroundImage: `url(${iconPathURL(`home/i4.png`)})`,
                    backgroundRepeat: "round",
                  }}
                >
                  <h3 className="tile-content"><span>{constants.tile2}</span></h3>
                </section>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                <section
                  className="feature-icon"
                  style={{
                    backgroundImage: `url(${iconPathURL(`home/i3.png`)})`,
                    backgroundRepeat: "round",
                  }}
                >
                  <h3 className="tile-content"><span>{constants.tile3}</span></h3>
                </section>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                <section
                  className="feature-icon"
                  style={{
                    backgroundImage: `url(${iconPathURL(`home/i2.png`)})`,
                    backgroundRepeat: "round",
                  }}
                >
                  <h3 className="tile-content"><span>{constants.tile4}</span></h3>
                </section>
              </div>
            </div>
          </div>
        </section>

        <Layout1
          classParentName="element-container element-container-star"
          title={homeData[2].title}
          content={homeData[2].p1}
          img={"bepart.png"}
          className="common-content"
          type="star"
        />
      </div>
      <Layout1
        classParentName="element-container element-container-health"
        title={homeData[3].title}
        content={homeData[3].p1}
        img={""}
        className="common-content"
        type="ehs"
      />
      <div
        style={{
          backgroundImage: `url(${iconPathURL(`home/who.png`)})`,
          backgroundRepeat: "round",
        }}
      ></div>
      <div className="row">
      <NewsRoom setImage={(img)=>setImage(img)} setVisible={(visible) => setVisibility(visible)} />
      </div>
      </section>
    </div>
  );
}
