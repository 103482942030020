import React from 'react';

const CardSingal = (props) => {
    return (
        <>
            <div className='col-md-6 col-lg-4'>
                <div className='card-singal'>
                    <img src={props.BGImg} className='img-fluid' />
                    <div className='card-list'>
                        <h3>{props.TitleText}</h3>
                        <p>{props.ParaText}</p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CardSingal;