export default {
  tile1: `PRODUCT DESIGN AND \n DEVELOPMENT`,
  tile2: "ELECTRICAL AND ELECTRONICS",
  tile3: "COMPUTER-AIDED ENGINEERING",
  tile4: "MERCEDES-BENZ TECH INDIA",
  ehsText:
    "This policy shall be reviewed on a regular basis for its continued suitability and effectiveness.",
  ehsFooter: "Environment, Sustainability Factsheet 2021",
  bioMedical: "Biomedical Waste Report",
};
