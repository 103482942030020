import React, { Suspense } from "react";
import "./banner.scss";

export default function Button(props) {
  return (
    <div className="banner-content">
      <div className="img-content ">
        <img className="img-content " src={props.imgSrc}></img>
        <h1 className={`press-text${" "}${props.widthStyle}`}>{props.title}</h1>
        <p className={`details-text${" "}${props.widthStyle}`}>
          {props.details}
        </p>
      </div>
    </div>
  );
}
