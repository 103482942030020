import React, { useState, useEffect } from "react";
import "./whatWeDo.scss";
import "intersection-observer";
import Banner from "../../common/Banner/banner";
import data from "./whatWeDo.json";
import { Loader } from "../../common/Loader/Loader";
import HairLine from "./HairLine";
import iconPathURL from "../../common/iconPath";
const iconPath = process.env.PUBLIC_URL + "/assets/images/whatWeDo/";

export default function WhatWeDoContent(props) {
  // const [loading, setLoading] = useState(true);
  console.log("props src", props);
  const ptitle = props.paragraph_title;
  const listItemsTitle = props.whatWeDoData.map((data) => (
    <>
      <div className="text-container-section col-xs-12 col-sm-12 col-md-10 col-lg-10">
        <div className="row">
          <div className="col-lg-8 col-md-8 col-sm-8 col-xs-12">
            <h2 className="title-content">{data.title}</h2>
            <p>{data.description}</p>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
            {<img src={iconPathURL(data.src)} width="100%"></img>}
          </div>
        </div>
      </div>

      <HairLine />
      {/* <div className = "divider">
   <hr />
   </div> */}
    </>
  ));

  return (
    <div>
      <Banner
        imgSrc={`${iconPath}whatwedo_background.png`}
        title="What we do"
      />

      {/* <div className = "divider">
   <hr />
   </div> */}

      <div className="first-p">
        <div className="text-container-section col-xs-12 col-sm-12 col-md-10 col-lg-10 row">
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
            <p className="title-p">{ptitle}</p>
          </div>
        </div>
      </div>
      <HairLine />
      {listItemsTitle}
    </div>
  );
}
