import React, { useState, useEffect,useRef } from "react";
import "./pwdsMbrdi.scss";

import 'bootstrap/dist/css/bootstrap.min.css';
import { Link, useHistory } from "react-router-dom";
import Button from "../../common/Buttons/button";
import Modal from "../../common/Modal/modal";
import ContactFooter from "../../common/ContactFooter/contactFooter";


import Banner from "../../common/Banner/banner";
import iconPathURL from "../../common/iconPath";
import questions from "./questions.json";
import $ from 'jquery';
const iconPath = process.env.PUBLIC_URL + "/assets/images/";
const iconPathBaseUrl = process.env.PUBLIC_URL + "/assets/icons/";

export default function PwdsMbrdi(props) {
  const jobsRef = useRef(null)
  const navigate = useHistory();
  const [isVisible, setVisibility] = useState(false);
  const [image, setImage] = useState("");
  const [isActive,setIsActive] = useState(false);
  const [listOfQuestions,setListOfQuestions] = useState(questions)
  const toggleMenu = (index) =>{
    setIsActive(true)
    let menus = {...listOfQuestions};
    console.log(menus['menus'][index]);
    if(menus['menus'][index]['isActive']){
    
        $("#"+index+"_subLinks1").slideUp("slow");
    }else{
     
        $("#"+index+"_subLinks1").slideDown("slow");
    }
    menus['menus'][index]['isActive'] = !menus['menus'][index]['isActive'];
    setListOfQuestions(menus);
    
}
const toggleSubMenu = (index,subIndex) =>{
  let menus = {...listOfQuestions};
  console.log(menus['menus'][index]);
  if(menus['menus'][index]['subMenu'][subIndex]['isActive']){
      $("#"+subIndex+"subLinks1").slideUp("slow");
  }else{
      $("#"+subIndex+"subLinks1").slideDown("slow");
  }
  menus['menus'][index]['subMenu'][subIndex]['isActive'] = !menus['menus'][index]['subMenu'][subIndex]['isActive']
  setListOfQuestions(menus);
  
}

const navigateLink = (link) =>{
  navigate.push(link);
 
}

function showJobs (){
  jobsRef.current.scrollIntoView( {behavior: 'smooth' }) 
}

function footerTemplate(){
  return(

    <div>
   Feel free to write in to  <a  href="mailto:mbrdi_diversability@mercedes-benz.com">mbrdi_diversability@mercedes-benz.com</a>should you have further questions around PwD candidatures and our hiring process.
    </div>
  )
}
  return (
    <div className="pwd-container">
   
      <div className="banner-parent-conetnt">
      <img src={iconPathURL('pwds/pwds.jpg')} className="banner-image"/>
        <div className="banner-content">
                <h2 className="banner-title1"> PwDs @MBRDI: Providing an Equitable Platform</h2>
                <h2 className="banner-title2"> for Rewarding Careers</h2>
               
          </div>
        </div>
      <div className="pwd-center-container col-xs-12 col-sm-12 col-md-8 col-lg-8">
          
         
          <section className="first-section">
              <p className="first-p">
              Intricate layers make a workplace a comprehensive ecosystem, where everyone owns their piece in the larger organisational objective and realises their contributions are valued and appreciated. Inclusion of Persons with Disabilities (PwDs) is that unique dimension that truly makes MBRDI an inclusive workplace. 
              </p>
              <p className="second-p">
              Looking inward first, our workforce was sensitised into welcoming the new segment of talent through interventions imbuing inclusion of PwD across levels. Further, MBRDI went on to formulate a recruitment strategy to hire Persons with Disabilities (PwDs) – especially the engineering talent – enabling them to take on roles curated to set them up for success and a comprehensive growth plan with equitable policies and mentorship avenues built in.
              </p>
          </section>
          <section  className="second-section">
              <h2>
              MBRDI is accepting Applications for PwD Experienced candidates and Fresher PwD graduates for its Mercedes-Benz Cars – CSO, Mercedes-Benz Cars – RD and IT functions.
              </h2>
              <p>
              Since arriving at well-suited role fitments and setting up rewarding pathways for PwDs is key to us at MBRDI, we’re pleased to invite PwD candidatures – Experienced and Fresher for our internship programme, through an easy-to-understand recruitment process. We are committed to providing reasonable accommodation to all eligible candidates based on individual needs to participate in the selection process.
              </p>
              <p>
              Points to remember prior to applying:
                <ul>
                  <li> We are accepting applications of PwD Experienced candidates and Fresher PwD graduates for our Mercedes-Benz Cars – CSO, Mercedes-Benz Cars – RD and IT functions</li>
                  <li> Open for candidates with locomotor, hearing and vision disabilities (disability percentages confirmed to be 40% or above)</li>
                  <li>Opportunities open to those with or pursuing full-time degree courses, recognised by the Central/State Government of India</li>
                  <li>At the time of the selection process, all arrears and backlogs should have been cleared</li>
                  <li>Please click on the below button to find details on Job Description, required Skillset, Qualification etc.</li>
                </ul>
                
                  <div className="first-button-content"><a target="NEW" href="https://ams.hirepro.in/registration/MBRDI/mbpwd/openings/?event=15912"><button className="comon-blue-button"> Click here, for PwD Fresher candidates!  </button></a></div>
                  <div className="second-button-content"><a target="NEW" href="https://ams.hirepro.in/registration/MBRDI/mbpwd/openings/?event=39671"><button className="comon-blue-button">Click here, for PwD Experienced candidates! </button></a></div>
              </p>
          </section>

          
            <section className="pwd-application-section">
              <div className="">
              
           
                <div className="questions">
                    <h2>Frequently Asked Questions (FAQs):</h2>
                    <p className="pwds-steps">
                    Steps for applicant registration process:
                    </p>
                    <p className="pwds-steps">
                    As an applicant, you need to fill in all the particulars at the registration page.
                    </p>
                    <p className="accordian">
                    <ul className="links1">
                    {listOfQuestions['menus'].map((links,index) => (
                        <li className="questions-li"> 
                        {links['hasSubmenu'] &&
                            <div className="row toggle-row"  onClick={()=>toggleMenu(index)}>
                                <div className="col-lg-11 col-xs-11">
                                  <p className="question-title">{links['title']}</p>     
                                </div>
                                <div className="col-lg-1">
                                <span className="toggle-questions">{!links['isActive']?<img src={`${iconPathBaseUrl}/expand.png`}></img>:<img src={`${iconPathBaseUrl}/collapse.png`}></img>} </span>
                                </div>

                            </div>
                               
                              }
                              {!links['hasSubmenu'] &&
                                  <a target="new" href={links.title=="Event Report"?`${iconPath}Mercedes-Benz_Sustainability_Dialogue_India_2023_Report.pdf`:links['title']=="MBRDI on LinkedIn"&&"https://www.linkedin.com/company/mercedes-benz-research-and-development-india/"}
                                  class="primary-link"  onClick={()=>links.title!="Event Report"&&navigateLink(links['link'])}>{links['title']}
                                
                                    
                                      </a>    
                                        
                              }
                          
                      
                                {( links['hasSubmenu'])  &&
                                    <ul id={index+"_subLinks1"} className="subLinks">
                                      <li className="subLinksLi">
                                    {links['subMenu'].map((subLinks,subIndex) => (
                                            
                                            <React.Fragment>
                                             
                                              {(index<13 && index!=11  && index!=8) &&
                                              <p className="sub-text" >
                                                  {subLinks['isItalic']==true?<i>{subLinks['title']}</i>:<span >{subLinks['title']}</span>}
                                             
                                               </p>
                                               }
                                                {index==11 && 
                                                    <React.Fragment>
                                              <p className="sub-text" >
                                                For <strong>Freshers,</strong> shortlisted candidates will receive the link for online test once the application process is completed.
                                             
                                               </p>
                                                <p className="sub-text" >
                                                For <strong>Experienced Candidates</strong>, incase your profile gets shortlisted, you will get a call from MBRDI recruitment team for the interview steps.
                                             
                                               </p>
                                               </React.Fragment>
                                               }
                                                {index==13 && 
                                                  <p className="sub-text" >
                                                    In case of any such issue, please reach out to <a href="mailto:mbrdi_diversability@mercedes-benz.com">mbrdi_diversability@mercedes-benz.com</a>
                                                
                                                  </p>
                                                  }
                                                  {index==14 && 
                                                  <p className="sub-text" >
                                                    In case of any such issue, please reach out to  <a href="mailto:mbrdi_diversability@mercedes-benz.com">mbrdi_diversability@mercedes-benz.com</a>
                                                
                                                  </p>
                                               }

                                            {index==15 && 
                                             <React.Fragment>
                                                <p className="sub-text" >
                                                     As a practice, considering the volumes of applications received, we are unable to share contact details of company recruiters. You can write to <a href="mailto:mbrdi_diversability@mercedes-benz.com">mbrdi_diversability@mercedes-benz.com</a> for any questions.
                                              
                                                </p>
                                                <p>
                                                  The volumes of applications received makes it hard to contact applications not shortlisted for screening or interview. However, candidatures are in our database so opportunities in the future may be pursued.
                                                </p>
                                                </React.Fragment>

                                               }
                                               </React.Fragment>
                                          
                                    ))}
                                  
                                    
                                    {index==3 &&
                                   
                                      <p className="sub-text">
                                        <ul className="guidelines-ul">
                                          <li>Hard of hearing</li>
                                          <li>Locomotor disability</li>
                                          <li>Low vision</li>
                                        </ul>
                                      </p>
                                    }
                                       {index==8 &&
                                      <div>
                                          <p className="sub-text">A Typical selection process for <strong>Experienced</strong> Candidates will comprise of 3 rounds as mentioned below:</p>
                                      <p className="sub-text">
                                        <ul className="guidelines-ul">
                                          <li>Technical round</li>
                                          <li>Manager round</li>
                                          <li>HR round</li>
                                        </ul>
                                      </p>
                                       
                                      <p className="sub-text">
                                        A typical selection process for <strong>Freshers</strong> will comprise of 4 different rounds as mentioned below:      
                                      </p>
                                          
                                      <p className="sub-text">
                                      Online Assessment – 60 minutes: This assessment will be a combination of aptitude and specialization related questions.   
                                      </p >
                                      <p className="sub-text">
                                        <ul className="assessment-ul">
                                          <li>Technical round: Candidates who clear the online assessment will be appearing for the technical interview.</li>
                                          <li>Manager round: Candidates who clear the technical round will be appearing for the manager interview round.</li>
                                          <li>HR round: Candidates who clear the manager round will be appearing for the HR interview round.</li>
                                        </ul>
                                      </p>
                                      </div>
                                       }
                                    </li>
                                    </ul>
                                }
                            </li>
                        ))} 
                        </ul>
       
                    </p>
                </div>
              </div>
           </section>
          
          
         
       
      </div>
      <ContactFooter 
      html={()=>{
        return(
        <span>Feel free to write in to<a className="email-link" href="mailto: mbrdi_diversability@mercedes-benz.com">{" mbrdi_diversability@mercedes-benz.com"} </a>, should you have further questions around PwD candidatures and our hiring process.</span>
      )}}
      label="For media queries, please write to MBRDI Communications" />
        </div>
  );
}
