import React, { useState, useEffect } from "react";
import "./prideImpact.scss";
import "intersection-observer";
import Banner from "../../common/Banner/banner";
import data from "./pride.json";
import iconPathURL from "../../common/iconPath";
const iconPath = process.env.PUBLIC_URL + "/assets/images/prideImpact/";

export default function PrideImpact(props) {
  const prideData = data.data;
  const hairLine = () => {
    return (
      <hr
        style={{
          color: "#d3d3d3",
          margin: 20,
          height: 2,
          width: "100%",
        }}
      />
    );
  };
  return (
    <div className="pride-container">
      <Banner
        imgSrc={iconPathURL('prideImpact/pride_header.png')}
        title="The Pride And Impact"
      />
     
      <section className="text-container-section col-xs-12 col-sm-12 col-md-8 col-lg-8">
      <div className="first-box">
        <p className="para-content">Empowerment. Opportunity. Enablement. </p>
        <p className="para-content">
          Words, we believe, describe aptly, the ethos and spirit of people
          practices at MBRDI. We did however look inwards – i.e., at our own
          people – to vividly offer a peek into what it’s like to work at MBRDI.
          Here are their stories.
        </p>

        <h2 className="title-content">{prideData.title1}</h2>
        <img className="pride-content" src={iconPathURL('prideImpact/pride_1.png')} />
        <div className="heading-content">
          <span>Dr Avinash Penumaka</span>
          <span>Accident Researcher</span>
        </div>
        <p className="para-content">
          {prideData.p1} <i>{prideData.italic1}</i>{" "}
        </p>
        <p className="para-content">{prideData.p2}</p>
        <p className="para-content">{prideData.p3}</p>
        <p className="para-content">
          {prideData.p13}
          <i>{prideData.italic2}</i>
        </p>

        {hairLine()}

        <div className="second-content">
          <h2 className="title-content">{prideData.title2}</h2>
          <img className="pride-content" src={iconPathURL('prideImpact/pride_2.jpeg')} />
          <div className="heading-content">
            <span>Sanjib Ghosh</span>
            <span>Sr Program Manager</span>
          </div>
          <p className="para-content">{prideData.m1}</p>
          <p className="para-content">{prideData.m2}</p>
          <p className="para-content">{prideData.m3}</p>
          <p className="para-content">{prideData.m4}</p>
          </div>
        </div>
      </section>
    
      {/* <img className="img-content footer-content" src={`${iconPath}footer.png`}></img> */}
    </div>
  );
}
