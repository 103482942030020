import React, { Suspense,useState } from "react";
import $ from 'jquery';
import "./Header.scss";
//import Menu from "../../components/Menu/Desktop/Menu";
import MobileMenu from "../../components/Menu/Mobile/Menu";
import DeskTopMenu from "../../components/Menu/Desktop/Menu";
import { set } from "immutable";
import  {FontAwesomeIcon}  from '@fortawesome/react-fontawesome'
import iconPathURL from "../../common/iconPath";
const iconPath = process.env.PUBLIC_URL + '/assets/images/';
export default function Header(props) {
    const [showMobileLinks,setShowMobileLinks] =useState(false)
    const [count,setCount] =useState(0)
    
    const toggleMenu = () =>{
        setCount(count+1);
        if(showMobileLinks){
            $(".mobile-links").slideUp("slow");
        }else{
            $(".mobile-links").slideDown("slow");
        }
        setShowMobileLinks(!showMobileLinks); 
    
    }

    const toggle = () =>{
        $(".mobile-links").slideUp("slow");
        setShowMobileLinks(!showMobileLinks); 
    }
    
  return (
    <div>

        <div className="header">

            <div className="site-header-inner">
                    <div className="row mobile-menu mobile-content">
                        <div className="col-xs-8 col-sm-3 col-md-3 col-lg-4 logo-container">
                             <a className="logo-link primary-link" href="#/"><img src={iconPathURL('logo/logo.svg')} className="logo"></img>
                        </a>
                        </div>
                        <div className="col-xs-4 col-sm-9 col-md-8 menu-toggle-container ">
                
                               {count==0?<img style={{height:16,width:16}} src={iconPathURL('common/menu.png')}></img>: <img style={{height:16,width:16}} src={iconPathURL('common/menu_blue.png')}></img>}
                               {count==0? <button className="toggle-first"  onClick={toggleMenu}>{"Menu"}</button>:
                                 <a className="primary-link" onClick={toggleMenu}><span>{!showMobileLinks?"Menu":"Close"}</span></a>}
                        </div>
                       
                    </div>
                    <div className="desktop-menu">
                    <div className="logo-container">
                             <a className="logo-link primary-link" href="#/"><img src={iconPathURL('logo/logo.svg')} className="logo"></img>
                        </a>
                        </div>
                         <div className="dektop-menu-list">

                        <DeskTopMenu showMenu={props.showMenus}></DeskTopMenu>
                        </div>
                    </div>
            </div>
            
            </div>
            <div className="mobile-links">
               <MobileMenu toggle={toggle}></MobileMenu>
            </div>
           
      </div>

  );
}
