import React, { useState,useEffect } from "react";
import "./newsRoom.scss";
import ReactCarousal from "../../common/carousel/carousel";
import {Link,useHistory} from "react-router-dom"
import iconPathURL from "../../common/iconPath";
const iconPath = process.env.PUBLIC_URL + "/assets/images/";
 

export default function NewsRoom(props) {
    const navigate = useHistory();
    const [isIe,setIsIe] = useState("news-container")
    useEffect(()=>{
      var isIE = /*@cc_on!@*/false || !!document.documentMode;
      var isEdge = !isIE && !!window.StyleMedia;
    
      if(navigator.userAgent.indexOf("Edg")!=-1){
       
        setIsIe("news-container ie")
      }
      console.log(document)
      console.log("------------")
    },[])
    
  return (
    <div
      className={isIe}
    >
     
    <h2 className="flex-title">Newsroom</h2>
    <ReactCarousal>
    <div className="news-content">
   <a target="new" href="https://auto.economictimes.indiatimes.com/amp/videos/future-car-will-rely-more-on-deep-tech-and-ai-manu-saale-md-and-ceo-mbrdi/100157669"  >
   <button className="news-content"><img className="news-tile"  src={iconPathURL('common/newsroom_1.png')}/></button>
    </a>
   </div>
  
 
   <div className="news-content">
   <a target="new" href="https://www.thehindu.com/news/cities/bangalore/mercedes-benz-clears-46000-kg-of-waste-85-lakh-litres-of-pooled-water-from-nandi-hills-as-part-of-five-year-project/article67312817.ece"  >
   <button className="news-content"><img className="news-tile"  src={iconPathURL('common/newsroom_2.png')}/></button>
    </a>
   </div>

   <div className="news-content">
   <a target="new" href="https://indianexpress.com/article/education/iisc-mercedes-benz-sign-mou-for-research-collaboration-9039540/"  >
   <button className="news-content"><img className="news-tile"  src={iconPathURL('common/newsroom_3.png')}/></button>
    </a>
   </div>

   <div className="news-content">
   <a target="new" href="https://www.peoplematters.in/article/employee-engagement/foster-a-work-culture-that-prioritises-employee-wellbeing-says-mahesh-medhekar-vp-hr-of-mbrdi-39655"  >
   <button className="news-content"><img className="news-tile"  src={iconPathURL('common/newsroom_4.png')}/></button>
    </a>
   </div>

   <div className="news-content">
   <a target="new" href="https://timesofindia.indiatimes.com/business/india-business/transforming-engineering-design-with-genai/articleshow/106199986.cms?from=mdr"  >
   <button className="news-content"><img className="news-tile"  src={iconPathURL('common/newsroom_5.png')}/></button>
    </a>
   </div>

   <div className="news-content">
   <a target="new" href="https://auto.economictimes.indiatimes.com/news/passenger-vehicle/mercedes-benz-7-point-approach-to-ev-safety/107114068"  >
   <button className="news-content"><img className="news-tile"  src={iconPathURL('common/newsroom_6.png')}/></button>
    </a>
   </div>

    </ReactCarousal>
    
   
   
    <div className="section">
    <div className="row footer-button">
        <div className="col-lg-6 col-sm-12">
          <button onClick={()=>navigate.push("/press-release")} className="button-content">Popular</button>
          </div>
          <div className="col-lg-6 col-sm-12">
          <button onClick={()=>navigate.push("/in-the-news")} className="button-content">Latest</button>
          </div>
          </div>
   
          </div>   
          <img style={{width:'100%'}} src={iconPathURL('common/newsroom_back.png')}></img>
    </div>
  );
}